/* Global Styles */
.albumsIndex {
    height: 100vh;
    scroll-snap-type: y mandatory;
    background: #1a1a1a;
    overflow-y: scroll;
}

/* Navigation Styles */
.mainNav {
    position: sticky;
    top: 0;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1a1a1a;
    padding: 20px 40px;
}

.mobileNav{
    display: none;
}

.navElements {
    max-width: 980px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navTrigger {
    font-family: 'Fjalla One', sans-serif;
    font-size: 18px;
    background: transparent;
    padding: 0;
    color: #e6e6e6;
    border: solid 0px;
    opacity: 0.5;
    cursor: pointer;
    text-transform: uppercase;
    transition: 0.5s;
}

.navTrigger:hover{
    opacity: 1;
}

.activeTrigger {
    text-decoration: underline;
    opacity: 1;
}

/* Album Element Styles */

.albumsWrapper{
    max-width: 980px;
    height: auto;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    scroll-margin-top: 80px;
    margin: 0 auto;
    padding: 40px;
}

.albumsWrapper > *{
    flex: 1;
}

.albumsElement {
    min-width: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 40px;
}

.albumsElement > * {
    flex: 1;
}

.textElement {
    min-width: 320px;
}

.spotifyPlayer {
    min-width: 320px;
    max-width: 400px;
    width: 100%;
    height: 400px;
    border-radius: 20px;
}

/* Text Styles */
.rightText {
    margin-block-start: 0em;
    margin-block-end: 10px;
    line-height: 1;
}

.title {
    color: #e6e6e6;
    transition: color 0.5s;
}

.album {
    color: #f37878;
    transition: color 0.5s;
}

.year {
    font-size: 18px;
    color: #e6e6e6;
    transition: color 0.5s;
}

.albumsElement:hover .album {
    color: #ef4848;
}

.albumsElement:hover .title .year {
    color: white;
}

.albumCover {
    min-width: 320px;
    width: 100%;
    border-radius: 20px;
}

.flexTriggers {
    display: flex;
    align-items: center;
    row-gap: 10px;
    column-gap: 10px;
}

.playButton, .shareButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 45px;
    width: 45px;
    font-size: 14px;
    background: transparent;
    color: #e6e6e6;
    border: solid 2px #e6e6e6;
    border-radius: 6px;
    transition: 0.5s;
    cursor: pointer;
}

.playButton:hover, .shareButton:hover {
    color: white;
    border: solid 2px white;
}

.marginTop{
    margin-top: 20px;
}

.advertisingWrapper{
    display: none;
}

.unUsed{
    display: none;
}

.centered{
    text-align: center;
}

.margT{
    margin-bottom: 10px;
}

.mob{
    color: #1a1a1a;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    opacity: 1;
}

@media only screen and (max-width: 960px) {

    .mainNav {
        padding: 20px;
    }

    .albumsWrapper{
        max-width: 400px;
        margin: 0 auto;
        padding: 40px 20px;
    }
    
    .albumsElement {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: center;
        row-gap: 20px;
    }

    .navElements {
        max-width: 400px;
    }

    .marginTop{
        margin: 20px 0;
    }

    .hidden{
        display: none;
    }

    .navElements {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .mobileNav{
        display: block;
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 10px;
        background: #1a1a1a;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        row-gap: 20px;
        box-sizing: border-box;
    }

    .mobileNav button{
        font-size: 22px;
    }      
}

/* Media Query */
@media only screen and (max-width: 768px) {

    .advertisingWrapper{
        display: block;
    }    
    
    .adElement{
        background: #e6e6e6;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 40px;
        box-sizing: border-box;
        border-radius: 20px;
    }
     
    .appInstall{
        Color: #1a1a1a;
    }

    .installButton{
        background-color: #1a1a1a;
        padding: 12px 24px;
        border: none;
        color: #e6e6e6;
        font-size: 18px;
        margin-top: 20px;
        border-radius: 6px;
        width: 100%;
    }

    .album {
        line-height: 1.3;
    }
        
}