@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Oswald:wght@400;500;600&display=swap');

body {
  margin: 0;
  font-family: 'Oswald', sans-serif, 'Fjalla One', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-family: 'Oswald', sans-serif;
  font-size: 96px;
  text-transform: uppercase;
  font-weight: 600;
}

h3 p {
  font-family: 'Fjalla One', sans-serif;
}

h3{
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 600;
}

button {
  font-family: 'Oswald', sans-serif;
}

@media only screen and (max-width: 960px) {
  h1{
    font-size: 72px;
  }  

  h3{
    font-size: 22px;
  }  
}

@media only screen and (max-width: 768px) {
  h1{
    font-size: 48px;
  }  
}